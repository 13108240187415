import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Contact</h2>
        <p>Get in contact with us - we would be happy to help</p>
      </header>
    </article>
    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <ul className="icons">
          <li className="fa fa-flag">
            <h3>Visit Us</h3>
            <a
              href="https://www.google.com/maps/place/362+Danbury+Rd,+New+Milford,+CT+06776"
              target="_blank"
              rel="noreferrer"
            >362 Danbury Road, New Milford, CT 06776</a>
          </li>
        </ul>
        <br/>
        <ul className="icons">
          <li className="fa fa-phone">
            <h3>Call Us</h3>
            <a
              href="tel:+12038846042"
            >(203) 884-6042</a>
          </li>
        </ul>
        <br/>
        <ul className="icons">
          <li className="fa fa-envelope-open">
            <h3>Email Us</h3>
            <h5>
              <a href="mailto:Adam@candlewoodlakedocks.com?subject=Re%3A%20A%26J%20Dock%20Builders%20Site">Adam</a>
              <span>{`  |  `}</span>
              <a href="mailto:Justin@candlewoodlakedocks.com?subject=Re%3A%20A%26J%20Dock%20Builders%20Site">Justin</a>
            </h5>
          </li>
        </ul>
      </div>
    </section>
    <section>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d373.34935800691153!2d-73.42419061858047!3d41.53038394841937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7f7aeccb9a7cf%3A0xabdcbb1c37c88ffb!2s362%20Danbury%20Rd%2C%20New%20Milford%2C%20CT%2006776!5e0!3m2!1sen!2sus!4v1683486429030!5m2!1sen!2sus"
        style={{"border":0}} allowFullScreen="" width="100%" height="500" frameBorder="0"/>
    </section>
  </Layout>
);

export default IndexPage;
